import { Box, Icon, Link, List, Button } from '@mui/material';
import {
  EthIcon,
  TrenchesIcon,
  ParachuteIcon,
  UserCircleIcon,
} from '../components/icons';
import { LogoWithText } from '../components/LogoWithText';
import { RefCompaignSection } from '../components/RefCompaignSection';
import { WalletControls } from '../components/WalletControls';
import {
  FINANCE_PATH,
  HOME_PATH,
  POINTS_PATH,
  PROFILE_PATH,
  SIGNUP_AS_CREATOR_PATH,
} from '../constants/routings';
import useCurrentUserData from '../hooks/auth/useCurrentUserData';
import { CountdownProvider } from '../providers/CountdownProvider';

import MenuItem from './MenuItem';
import { usePageContext } from '../renderer/usePageContext';
import navigate from '../lib/navigate';

const LINKS = [
  {
    text: 'Trenches',
    href: HOME_PATH,
    icon: TrenchesIcon,
    alwaysVisible: true,
  },
  { text: 'Points', href: POINTS_PATH, icon: ParachuteIcon },
  { text: 'Finance', href: FINANCE_PATH, icon: EthIcon, restricted: true },
  {
    text: 'Profile',
    href: PROFILE_PATH,
    icon: UserCircleIcon,
    alwaysVisible: true,
  },
];

export default function LeftSidebar() {
  const { data: userData } = useCurrentUserData();
  const pageContext = usePageContext();

  const onboardingComplete = userData?.onboarding_complete || null;
  const isCreator = userData?.user_type === 'creator';
  const isOnboardedCreator = isCreator && onboardingComplete;

  // Permissions to view tabs
  const canViewRestrictedTabs = isOnboardedCreator;
  const canViewTabs = !isCreator || isOnboardedCreator;

  const isLoggedIn = !!userData;

  return (
    <Box
      component="aside"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mx: 3,
        mt: { xs: 17, md: 8 },
        overflow: 'hidden',
        height: 'calc(100vh - 40px)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: 8,
        }}
      >
        <Link href={HOME_PATH}>
          <Icon
            sx={{
              width: { xs: 'auto', md: 180, xl: 'auto' },
              height: { xs: 'auto', md: 42, xl: 'auto' },
            }}
          >
            <LogoWithText />
          </Icon>
        </Link>
      </Box>

      <Box sx={{ overflowY: 'auto' }}>
        <List
          sx={{
            minWidth: '272px',
            gap: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {LINKS.map(
            ({ text, href, icon: Icon, restricted, alwaysVisible }) => {
              if (restricted && !canViewRestrictedTabs) return null;
              if (!alwaysVisible && !restricted && !canViewTabs) return null;

              return (
                <MenuItem key={text} href={href} text={text} Icon={<Icon />} />
              );
            },
          )}
        </List>
      </Box>

      {isLoggedIn && (
        <CountdownProvider>
          <RefCompaignSection />
        </CountdownProvider>
      )}

      <Box flex={1} />

      {!isLoggedIn ? (
        <Button
          variant="outlined"
          color="primary"
          sx={{ position: 'absolute', bottom: 40, width: '272px' }}
          onClick={() => navigate(SIGNUP_AS_CREATOR_PATH)}
        >
          Launch your community
        </Button>
      ) : (
        <WalletControls
          display="flex"
          flexDirection="column"
          gap={6}
          sx={{ position: 'absolute', bottom: 40, width: '272px' }}
        />
      )}
    </Box>
  );
}
