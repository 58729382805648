import { Address } from 'viem';
import { useSimulateContract } from 'wagmi';

import SubstakingVault from '../../../assets/abi/SubstakingVault';

export function useWithdrawWethSimulateContract(
  vaultContractAddress: Address | undefined,
  amount: bigint | null,
) {
  return useSimulateContract({
    address: vaultContractAddress,
    abi: SubstakingVault,
    functionName: 'withdrawWeth',
    args: [amount ?? 0n],
    query: {
      enabled: !!vaultContractAddress && !!amount,
    },
  });
}
