import { Box, IconButton, Typography } from '@mui/material';
import { CloseIcon } from './icons';
import navigate from '../lib/navigate';
import { EDIT_PROFILE_PATH } from '../constants/routings';

export function PreviewBanner() {
  const handleClose = () => {
    navigate(EDIT_PROFILE_PATH);
  };

  return (
    <Box
      sx={{
        position: 'sticky',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#383B3D',
        top: 0,
        zIndex: 1200,
        w: '100%',
        mr: { xs: 0, md: 10 },
        minWidth: { xs: '100vw', md: '1200px' },
      }}
    >
      <Typography sx={{ py: 3 }}>Preview mode</Typography>

      <IconButton
        sx={{
          position: 'absolute',
          right: { xs: 20, md: 0 },
          height: '34px',
          width: '34px',
          top: 5,
          svg: {
            stroke: '#9B9FA3',
          },
        }}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
}
