import { Address, erc20Abi } from 'viem';
import { useAccount, useReadContract } from 'wagmi';

import formatBigintWithDecimals from '../lib/formatBigintWithDecimals';

import { useMainTokenInfo } from './useMainTokenInfo';

export function useWethBalance() {
  const { address } = useAccount();
  const {
    data: balance,
    refetch: refetchBalance,
    isLoading,
  } = useReadContract({
    address: import.meta.env.VITE_TOKEN_CONTRACT_ADDRESS as Address,
    abi: erc20Abi,
    functionName: 'balanceOf',
    args: [address!],
  });

  const { decimals } = useMainTokenInfo();
  const hasEnoughBalance = !!balance;

  const formatBalance = (fractionDigits: number = 6) => {
    return formatBigintWithDecimals(balance, decimals, fractionDigits);
  };

  return {
    balance: balance,
    formatBalance,
    hasEnoughBalance,
    refetchBalance,
    isLoading,
  };
}
