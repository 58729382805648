import { useEffect, useRef } from 'react';
import { useSnackbar } from 'notistack';

import { useQueryClient } from '@tanstack/react-query';

import useSupabase from '../api/supabase';
import {
  getTelegramDataQueryKey,
  invalidateTelegramData,
} from '../api/users/useUserTgDataByIdQuery';
import { ITelegramData } from '../types/ITelegramData';

import useCurrentUserData from './auth/useCurrentUserData';
import useGlobalModal from './useGlobalModal';
import { usePostHogCapture } from './usePostHogCapture';

export function useTgConnection() {
  const { enqueueSnackbar } = useSnackbar();
  const { showModal, hideModal } = useGlobalModal();
  const { data: userData } = useCurrentUserData();
  const { captureConnectTelegram } = usePostHogCapture();

  const queryClient = useQueryClient();
  const subscriptionRef = useRef<any>(null);
  const supabase = useSupabase();

  useEffect(() => {
    if (!userData?.id) {
      return;
    }

    // Unsubscribe from the previous subscription if it exists
    if (subscriptionRef.current) {
      subscriptionRef.current.unsubscribe();
    }

    const subscription = supabase
      .channel('telegram-users-updates')
      .on(
        'postgres_changes',
        {
          event: 'UPDATE',
          schema: 'public',
          table: 'telegram_users',
          filter: `user_id=eq.${userData?.id}`,
        },
        (payload) => {
          const updatedData = payload.new;

          if (updatedData.tg_chat_id && !payload.old.tg_chat_id) {
            queryClient.setQueryData(
              getTelegramDataQueryKey(userData?.id),
              (oldData: ITelegramData) => {
                return !oldData || oldData?.user_id === updatedData.user_id
                  ? updatedData
                  : oldData;
              },
            );
            hideModal('ConnectTelegramModal');
          }
        },
      )
      .subscribe();

    // Store the current subscription in the ref
    subscriptionRef.current = subscription;

    return () => {
      if (subscriptionRef.current) {
        subscriptionRef.current.unsubscribe();
      }
    };
  }, [userData?.id]);

  const connectTg = async () => {
    (window as any).Telegram.Login.auth(
      {
        bot_id: import.meta.env.VITE_BOT_ID,
      },
      async (data: any) => {
        if (!data) {
          return;
        }
        const response = await fetch(
          '/api/tg?' + new URLSearchParams(data).toString(),
        );
        const json = await response.json();

        if (!response.ok) {
          enqueueSnackbar(`Error: ${json.message}`, {
            variant: 'error',
          });
          return;
        }

        invalidateTelegramData(queryClient, userData?.id);
        captureConnectTelegram({ telegram_account_id: json?.tg_user_id });

        if (userData.user_type === 'creator') {
          connectTgChannel();
        } else {
          enqueueSnackbar(
            `User connected TG (${json.tg_user_id}) successfully`,
            {
              variant: 'success',
            },
          );
        }
      },
    );
  };

  const connectTgChannel = () => {
    showModal({
      modalType: 'ConnectTelegramModal',
      modalProps: undefined,
    });
  };

  return { connectTg, connectTgChannel };
}
