export const LEARN_MORE_KOL_GAMES_LINK =
  'https://altcoinist.notion.site/What-Are-The-Creator-Games-COMING-SOON-d281a7061e4648f8a4d5f2c717366b54?pvs=4';

export const LEARN_MORE_LEADERBOARD_RANK_LINK =
  'https://altcoinist.notion.site/Altcoinist-Leaderboard-Rankings-49104bfc950540519e31fee023170579?pvs=4';

export const LEARN_MORE_PULL_PAYMENTS_TOC_LINK =
  'https://altcoinist.notion.site/Altcoinist-Pull-Payments-ToC-97766210752a4f9b990db42e73835fb1?pvs=4';

export const LEARN_MORE_APY_CALCULATION_LINK =
  'https://altcoinist.notion.site/Community-Pool-APY-Calculation-e1f01df712b9410dbe19f00ec5badb9a?pvs=4';

export const LEARN_MORE_TELEGRAM_BOT_GUIDE_LINK =
  'https://altcoinist.notion.site/Adding-Altcoinist-To-Your-Telegram-Group-d4439fbdfd3244fc9b44f320980fb4b0?pvs=4';

export const LEARN_MORE_ABOUT_SETUP_PRICING_LINK =
  'https://altcoinist.notion.site/Fees-and-Pricing-97c870b4cb5a4245a0f434b93a8bbe89?pvs=4';

export const LEARN_MORE_ABOUT_STAKING_IN_COMMUNITY_POOLS_LINK =
  'https://altcoinist.notion.site/Community-Pool-Staking-54147cf7c34b4459a62d024daa3f75d8';

export const LEARN_MORE_CREATOR_GAMES =
  'https://altcoinist.notion.site/What-Are-The-Creator-Games-COMING-SOON-d281a7061e4648f8a4d5f2c717366b54';

export const LEARN_MORE_YIELD_BOOST_MECHANISM =
  'https://altcoinist.notion.site/Guide-to-Earn-sXP-with-Staking-7d9bf6003e7144fcb2f0f19494197e75?pvs=4';

export const LEARN_MORE_PULL_PAYMENT_TERMS_LINK =
  'https://altcoinist.notion.site/Pull-Payments-Terms-and-Conditions-97766210752a4f9b990db42e73835fb1';

export const AUDIT_REPORT_LINK =
  'https://github.com/altcoinist-com/contracts/blob/master/halborn-audit.pdf';

export const PAYMENT_COLLECTOR_CONTRACT_CODE_LINK =
  'https://github.com/altcoinist-com/contracts/blob/master/src/PaymentCollector.sol';

export const COINBASE_SMART_WALLET_LINK = 'https://wallet.coinbase.com/';

export const COINBASE_SMART_WALLET_SWAP_LINK =
  'https://wallet.coinbase.com/swap';

export const LEARN_MORE_ALTCOINIST_TERMS_OF_USE =
  'https://altcoinist.notion.site/Altcoinist-Terms-of-Use-db11a4057b4e462f89df8ad3237771c3?pvs=4';

export const LEARN_MORE_PRIVACY_POLICY =
  'https://altcoinist.notion.site/Privacy-Policy-916dd6ca078847ceb7c5c0d3d46ebf6f';

export const LEARN_MORE_CREATOR_AFFILIATES =
  'https://altcoinist.notion.site/Earn-With-Altcoinist-Creator-Affiliates-46e2e6c4fc4c4df5922a595f892375e1?pvs=4';

export const LEARN_MORE_POINTS_PROGRAM =
  'https://altcoinist.notion.site/Altcoinist-Points-Program-35b46b4c1d694bfca575902f9d90220b?pvs=4';

export const LEARN_MORE_UNSTAKING =
  'https://altcoinist.notion.site/Guide-to-Earn-sXP-with-Staking-7d9bf6003e7144fcb2f0f19494197e75?pvs=4';

export const LEARN_HOW_TO_FIX_WRONG_WALLET =
  'https://altcoinist.notion.site/Smart-Wallet-Troubleshooting-112b35d787488029be8ac651e1db4be6?pvs=4';

export const SMART_WALLET_GUIDE =
  'https://www.loom.com/share/ac640138ba1c4cbd9133e7f757c6db67?sid=c226a928-92f5-4016-8eb5-0c097bda156b';

export const SHARED_REVENUE_GUIDE =
  'https://altcoinist.notion.site/Altcoinist-Revenue-Sharing-569fb024bca246e4bd047e096f20de2f?pvs=4';

export const COMMUNITY_STAKING_POOLS_GUIDE =
  'https://altcoinist.notion.site/Guide-To-Community-Staking-Pools-54147cf7c34b4459a62d024daa3f75d8?pvs=4';

export const DISCORD_CONNECTION_GUIDE =
  'https://altcoinist.notion.site/Discord-User-Guide-112b35d78748802c8af5c25d2d313e69?pvs=4';

export const WRAP_ETH_GUIDE_LINK =
  'https://www.notion.so/altcoinist/CSW-Swap-Guide-38028ed7c8aa42daa40dff7cffdabd26?pvs=4';
