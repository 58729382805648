import { Address, erc20Abi } from 'viem';
import { useSimulateContract } from 'wagmi';

export function getApproveContract(
  contractAddress: Address,
  amount: bigint,
): {
  address: Address;
  abi: typeof erc20Abi;
  functionName: 'approve';
  args: [Address, bigint];
} {
  return {
    address: import.meta.env.VITE_TOKEN_CONTRACT_ADDRESS as Address,
    abi: erc20Abi,
    functionName: 'approve',
    args: [contractAddress, amount],
  };
}

export function useApproveSimulateContract(
  contractSpenderAddress: Address,
  selectedPriceInWei: bigint | undefined,
) {
  console.log('useApproveSimulateContract', selectedPriceInWei);
  return useSimulateContract({
    ...getApproveContract(contractSpenderAddress, selectedPriceInWei ?? 0n),
    query: {
      enabled: !!contractSpenderAddress && !!selectedPriceInWei,
    },
  });
}
