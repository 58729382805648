import { Box, Divider, List } from '@mui/material';

import {
  EthIcon,
  TrenchesIcon,
  ParachuteIcon,
  ProfileIcon,
} from '../components/icons';
import { RefCompaignSection } from '../components/RefCompaignSection';
import {
  FINANCE_PATH,
  HOME_PATH,
  POINTS_PATH,
  PROFILE_PATH,
} from '../constants/routings';
import useCurrentUserData from '../hooks/auth/useCurrentUserData';
import { CountdownProvider } from '../providers/CountdownProvider';
import { usePageContext } from '../renderer/usePageContext';
import theme from '../theme/theme';

import MobileNavigationMenuItem from './MobileNavigationMenuItem';

const LINKS = [
  {
    title: 'Trenches',
    href: HOME_PATH,
    icon: TrenchesIcon,
    alwaysVisible: true,
  },
  { title: 'Points', href: POINTS_PATH, icon: ParachuteIcon },
  { title: 'Finance', href: FINANCE_PATH, icon: EthIcon, restricted: true },
  {
    title: 'Profile',
    href: PROFILE_PATH,
    icon: ProfileIcon,
    alwaysVisible: true,
  },
];

export default function MobileNavigation() {
  const pageContext = usePageContext();
  const { data: userData } = useCurrentUserData();

  const onboardingComplete = userData?.onboarding_complete || null;
  const isCreator = userData?.user_type === 'creator';
  const isOnboardedCreator = isCreator && onboardingComplete;

  // Permissions to view tabs
  const canViewRestrictedTabs = isOnboardedCreator;
  const canViewTabs = !isCreator || isOnboardedCreator;

  const isProfilePage = pageContext.urlPathname === PROFILE_PATH;
  if (!userData) return null;

  return (
    <Box
      component="nav"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'fixed',
        bottom: 0,
        width: '100%',
        zIndex: 40,
        borderTop: '1px solid #2D2F33',
        background: theme.palette.background.default,
      }}
    >
      {isProfilePage && userData && (
        <CountdownProvider>
          <RefCompaignSection />
        </CountdownProvider>
      )}
      <Box display="flex" justifyContent="space-around" alignItems="center">
        <Divider orientation="vertical" sx={{ background: '#2D2F33' }} />
        <List
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            width: '100%',
            padding: '0',
          }}
        >
          {LINKS.map(
            ({ title, href, icon: Icon, restricted, alwaysVisible }, index) => {
              if (restricted && !canViewRestrictedTabs) return null;
              if (!alwaysVisible && !restricted && !canViewTabs) return null;

              return (
                <MobileNavigationMenuItem
                  key={index}
                  href={href}
                  Icon={<Icon />}
                  title={title}
                />
              );
            },
          )}
        </List>
      </Box>
    </Box>
  );
}
