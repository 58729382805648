import { useAccount, useDisconnect, useSwitchChain } from 'wagmi';

import {
  Box,
  BoxProps,
  Button,
  ListItemIcon,
  MenuItem,
  MenuList,
  Typography,
} from '@mui/material';

import {
  COINBASE_SMART_WALLET_LINK,
  COINBASE_SMART_WALLET_SWAP_LINK,
} from '../constants/externalLinks';
import { useCopyToClipboard } from '../hooks/useCopyToClipboard';
import { useIsAppReady } from '../hooks/useIsAppReady';
import {
  GlobalConnectErrors,
  useGlobalConnectError,
} from '../hooks/useWalletConnectError';
import { truncAddress } from '../lib/truncHash';

import { ButtonMenu } from './shared/ButtonMenu';
import { CoinbaseSmartWalletButton } from './shared/Buttons/CoinbaseSmartWalletButton';
import {
  CopyIcon,
  LinkBreakIcon,
  MyWalletIcon,
  SwapIcon,
  WalletIcon,
} from './icons';

export function WalletControls({ ...props }: BoxProps) {
  const { address } = useAccount();
  const { disconnect } = useDisconnect();
  const { chains, switchChain } = useSwitchChain();

  const { copyToClipBoard } = useCopyToClipboard();

  const { errorType } = useGlobalConnectError();
  const { isReady } = useIsAppReady();

  const showWalletDetails =
    errorType === null ||
    errorType === GlobalConnectErrors.EmailNotLinked ||
    errorType === GlobalConnectErrors.DifferFromLinkedWallet ||
    errorType === GlobalConnectErrors.SubscriptionsExpiredSoon;

  const handleMyWallet = () => {
    window.open(COINBASE_SMART_WALLET_LINK, '_blank');
  };

  const handleSwap = () => {
    window.open(COINBASE_SMART_WALLET_SWAP_LINK, '_blank');
  };

  const handleCopyAddress = () => {
    if (!address) {
      return;
    }

    copyToClipBoard(address, `Wallet address copied.`);
  };

  if (!isReady) {
    return null;
  }

  return (
    <Box {...props}>
      {errorType === GlobalConnectErrors.NoWalletConnected && (
        <CoinbaseSmartWalletButton sx={{ width: '100%' }} />
      )}

      {errorType === GlobalConnectErrors.WrongNetwork && (
        <Box
          display="flex"
          flexDirection="column"
          gap={4}
          justifyContent="center"
          alignItems="center"
        >
          <Typography fontSize={14} fontWeight={400}>
            Switch to “Base” for your wallet network.
          </Typography>

          <Button
            onClick={() => switchChain({ chainId: chains[0]?.id })}
            sx={{ width: '100%' }}
          >
            Switch network
          </Button>
        </Box>
      )}

      {showWalletDetails && (
        <Box
          display="flex"
          flexDirection="row"
          gap={4}
          alignItems="center"
          sx={{
            backgroundColor: '#111314',
            borderRadius: '12px',
            pl: 4,
            py: 1,
          }}
        >
          <WalletIcon />

          <Typography fontSize={14} fontWeight={300} flex={1}>
            {truncAddress(address)}
          </Typography>

          <ButtonMenu title="Wallet menu">
            <MenuList>
              <MenuItem onClick={handleCopyAddress}>
                <ListItemIcon
                  sx={{
                    svg: {
                      fill: 'white',
                      width: 24,
                      height: 24,
                    },
                  }}
                >
                  <CopyIcon />
                </ListItemIcon>
                Copy address
              </MenuItem>

              <MenuItem onClick={handleMyWallet}>
                <ListItemIcon>
                  <MyWalletIcon />
                </ListItemIcon>
                My wallet
              </MenuItem>

              <MenuItem onClick={handleSwap}>
                <ListItemIcon>
                  <SwapIcon />
                </ListItemIcon>
                Swap
              </MenuItem>

              <MenuItem onClick={() => disconnect()}>
                <ListItemIcon>
                  <LinkBreakIcon />
                </ListItemIcon>
                Disconnect
              </MenuItem>
            </MenuList>
          </ButtonMenu>
        </Box>
      )}
    </Box>
  );
}
