import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Address } from 'viem';
import { useCallsStatus, useWriteContracts } from 'wagmi/experimental';

import { Box, Button, Divider, Tooltip, Typography } from '@mui/material';

import { ZERO_ADDRESS } from '../../../constants/web3';
import useGlobalModal from '../../../hooks/useGlobalModal';
import { useMainTokenInfo } from '../../../hooks/useMainTokenInfo';
import { usePriceInUsd } from '../../../hooks/usePriceInUsd';
import { useWalletData } from '../../../hooks/useWalletData';
import { formatBigint } from '../../../lib/formatBigInt';
import { ICreator } from '../../../types/ICreator';
import { FeedbackDetails } from '../../../web3/getErrorDetails';
import { useAllowanceReadMethod } from '../../../web3/hooks/ERC20/useAllowanceReadMethod';
import { getApproveContract } from '../../../web3/hooks/ERC20/useApproveSimulateContract';
import { getDepositContract } from '../../../web3/hooks/ERC20/useDepositSimulateContract';
import { useGetCreatorVaultByAddress } from '../../../web3/hooks/SubstakingFactory/useGetVaultByAddress';
import { getDepositWethContract } from '../../../web3/hooks/SubstakingVault/useDepositWethSimulateContract';
import { TermsAndPolicyTypography } from '../../TermsAndPolicyTypography';
import { FeedbackBanner } from '../FeedbackBanner';
import { TokenAmountInput } from '../Inputs/TokenAmountInput';
import { ModalContainer } from '../ModalContainer';

export type CreatorToStake = Pick<ICreator, 'wallet_address' | 'apy'>;

export interface StakeModalProps {
  creator: CreatorToStake;
}

const DEFAULT_STAKE_VALUE = 10000000000000000n;

export function StakeModal({ creator }: StakeModalProps) {
  const { hideModal } = useGlobalModal();
  const { enqueueSnackbar } = useSnackbar();
  const [valueInWei, setValueInWei] = useState<bigint | null>(
    DEFAULT_STAKE_VALUE,
  );

  const { symbol, decimals } = useMainTokenInfo();
  const { address, formatBalance, balance, hasEnoughBalance } = useWalletData();

  const valueInEth = formatBigint(valueInWei, decimals);

  const { data: creatorVaultAddress } = useGetCreatorVaultByAddress(
    creator?.wallet_address,
  );

  const { data: allowance } = useAllowanceReadMethod(
    address,
    creatorVaultAddress as Address,
  );

  const approved =
    valueInWei === 0n ||
    (!!allowance && !!valueInWei && allowance >= valueInWei);

  const hasCorrectVaultAddress =
    creatorVaultAddress && creatorVaultAddress !== ZERO_ADDRESS;

  const hasEnoughBalanceToStake =
    valueInWei === 0n || (balance && valueInWei && balance >= valueInWei);

  const onStakeSuccess = (hash: string) => {
    enqueueSnackbar(`Staked successfully! TX hash: ${hash}`, {
      variant: 'success',
    });
    hideModal();
    window.location.reload();
  };

  const { data: batchTxId, writeContracts } = useWriteContracts();
  const { data: callsStatus, failureReason: batchStakeFailureReason } =
    useCallsStatus({
      id: batchTxId as string,
      query: {
        enabled: !!batchTxId,
        // Poll every second until the calls are confirmed
        refetchInterval: (data) =>
          data.state.data?.status === 'CONFIRMED' ? false : 1000,
      },
    });

  const batchStakeFailureMsg = (batchStakeFailureReason?.cause as any)
    ?.shortMessage as string | undefined;

  const disabledBatchMethod =
    !valueInWei ||
    !creatorVaultAddress ||
    !address ||
    !hasEnoughBalanceToStake ||
    !hasCorrectVaultAddress;

  const handleStake = async () => {
    if (disabledBatchMethod) {
      return;
    }

    const contracts = [];
    if (!approved) {
      contracts.push(
        getApproveContract(
          creatorVaultAddress as Address,
          valueInWei as bigint,
        ),
      );
    }

    contracts.push(
      getDepositContract(valueInWei),
      getDepositWethContract(
        creatorVaultAddress as Address,
        address,
        valueInWei,
      ),
    );

    writeContracts(
      {
        contracts,
      },
      {
        onError: (error) => {
          enqueueSnackbar(`Stake failed, because of error: ${error.message}`, {
            variant: 'error',
          });
        },
        onSuccess: onStakeSuccess,
      },
    );
  };

  const setMaxAvailableValue = () => {
    setValueInWei(balance ?? null);
  };
  const { convertToUSD } = usePriceInUsd();

  const stakedAmountInUsdStr = valueInEth ? convertToUSD(+valueInEth) : 'N/A';

  const stakeErrorDetails = (() => {
    switch (true) {
      case !hasCorrectVaultAddress:
        return STAKE_ERRORS_MAP.INCORRECT_VAULT_ADDRESS;
      case !hasEnoughBalanceToStake:
        return STAKE_ERRORS_MAP.NOT_ENOUGH_BALANCE_TO_STAKE;
    }
    return null;
  })();

  return (
    <ModalContainer
      title="Stake"
      contentProps={{ sx: { width: 'min(calc(100% - 24px), 480px)' } }}
    >
      <Box mt={10}>
        <TokenAmountInput
          title="Enter amount"
          decimals={decimals}
          value={valueInWei}
          onValueChanged={setValueInWei}
          inputVariant="text"
          inputProps={{
            disabled: false,
          }}
        />

        <Box display="flex" justifyContent="space-between" mt={2}>
          <Typography color="text.secondary" fontSize={16} fontWeight={300}>
            {symbol}
          </Typography>

          <Typography>≈ {stakedAmountInUsdStr} USD</Typography>
        </Box>

        <Divider sx={{ my: 6 }} />

        <Box display="flex" alignItems="center">
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography color="text.secondary" fontSize={16} fontWeight={300}>
              Available balance
            </Typography>

            <Typography color="text.primary" fontSize={16} fontWeight={300}>
              {formatBalance()} {symbol}
            </Typography>
          </Box>

          <Button
            variant="outlined"
            color="primary"
            sx={{
              ml: 'auto',
            }}
            disabled={!hasEnoughBalance}
            onClick={setMaxAvailableValue}
          >
            Max
          </Button>
        </Box>

        <Divider sx={{ my: 6 }} />

        {/* <Box display="flex" gap={2} justifyContent="space-between" my={2}>
          <Typography color="text.primary" fontSize={16} fontWeight={300}>
            Pool APY
          </Typography>

          <Typography color="text.primary" fontSize={16} fontWeight={300}>
            {creator?.apy ?? 'N/A'}%
          </Typography>
        </Box> */}

        <Box display="flex" gap={2} justifyContent="space-between" my={2}>
          <Typography color="text.primary" fontSize={16} fontWeight={300}>
            Rewards frequency
          </Typography>

          <Typography color="text.secondary" fontSize={16} fontWeight={300}>
            Daily
          </Typography>
        </Box>

        <Box display="flex" gap={2} justifyContent="space-between" my={2}>
          <Typography color="text.primary" fontSize={16} fontWeight={300}>
            You&apos;re staking
          </Typography>

          <Typography color="text.primary" fontSize={32} fontWeight={500}>
            {valueInEth} {symbol}
          </Typography>
        </Box>

        {stakeErrorDetails && (
          <Tooltip title={stakeErrorDetails.body} enterTouchDelay={0}>
            <FeedbackBanner
              type={stakeErrorDetails.type}
              title={stakeErrorDetails.title}
              body={stakeErrorDetails.body}
            />
          </Tooltip>
        )}

        <Tooltip title={batchStakeFailureMsg}>
          <Box
            sx={{
              alignSelf: 'flex-start',
              my: 6,
            }}
          >
            <Button
              disabled={
                disabledBatchMethod ||
                (callsStatus && callsStatus.status !== 'CONFIRMED')
              }
              onClick={handleStake}
              fullWidth
            >
              Stake
            </Button>
          </Box>
        </Tooltip>

        <TermsAndPolicyTypography />
      </Box>
    </ModalContainer>
  );
}

const STAKE_ERRORS_MAP: Record<string, FeedbackDetails> = {
  PD: {
    title: 'User is not subscribed to creator.',
    body: 'Please, subscribe to this creator first.',
    type: 'error',
  },
  IP: {
    title: 'Staking amount should be more than 0.',
    body: 'Please, enter larger than 0 amount to stake.',
    type: 'error',
  },
  NOT_APPROVED: {
    title: 'Spending amount is not approved for this address.',
    body: 'Please, approve spending of selected amount of money.',
    type: 'warning',
  },
  INCORRECT_VAULT_ADDRESS: {
    title: "This creator doesn't have correct vault address",
    body: 'Creator should create vault by set up subscriptions prices',
    type: 'error',
  },
  NOT_ENOUGH_BALANCE_TO_STAKE: {
    title: "You don't have enough balance to stake this amount",
    body: 'Top up your wallet to complete this operation or select less amount to stake',
    type: 'error',
  },
};
